import { StatusBar } from 'expo-status-bar';
import { Image, ImageBackground, StyleSheet, View } from 'react-native';
import Svg, { Circle, TextPath, Text, Path, Rect } from 'react-native-svg';
import {
  useFonts,
  Audiowide_400Regular,
} from '@expo-google-fonts/audiowide';

import backgroundImage from './assets/background.jpg';
import toeImage from './assets/toe.png';
export default function App() {

  let [fontsLoaded] = useFonts({
    Audiowide_400Regular,
  });


  return (
    <View style={styles.container}>

      <ImageBackground source={backgroundImage} resizeMode="repeat" style={styles.bgContainer}>

        <Svg viewBox="0 0 500 500" style={styles.svg}>
          <Path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" fill="transparent" />
          <Text width="500" fill="#FF9800">
            <TextPath href="#curve">
              WE LOVE CRYPTOE
            </TextPath>
          </Text>
        </Svg>
        <Image style={styles.toe} source={toeImage} />

      </ImageBackground>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // backgroundColor: '#fff',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    // height: '100vh',
    // width: '100vw'

  },
  bgContainer: {
    // flex: 1,
    // backgroundColor: '#fff',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    // width: '100vw'

  },
  svg: {
    fontFamily: 'Audiowide_400Regular',
    fontSize: 38,
    textShadow: '0.5vw 0.5vh',
    height: '100vh',
    width: '100vw'
  },
  toe: {

    width: 400,
    height: 400,
    position: 'fixed',
    bottom: 0,



  }


});
